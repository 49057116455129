import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
	const subtitle = data.site.siteMetadata.subtitle

  return (
    <Layout location={location} title={siteTitle} subtitle={subtitle}>
      <Seo title="About" />
      <h2>About Me</h2>
			<p>Hi! I'm Kate, and I'm a Canadian developer writer. I write API and software developer documentation.</p>
			<p>I love to spend my free time learning programming languages, web development,
			game development, and other tech topics. This is my dev blog.</p>

			<p>Some of my posts on this blog are pulled from <a href="https://dev.to/kabartolo" target="_blank" rel="noopener noreferrer">DEV &#x2197;</a>
			{` `}
			using the <a href="https://developers.forem.com/api" target="_blank" rel="noopener noreferrer">DEV API &#x2197;</a>.</p>

			<h2>Published Articles</h2>
			Some of my work is published on other sites:
			<ul>
				<li><a href="https://blog.logrocket.com/use-django-rest-framework-to-build-a-blog/" target="_blank" rel="noopener noreferrer">Use Django REST Framework to build a blog [LogRocket]  &#x2197;</a></li>
				<li><a href="https://www.honeybadger.io/blog/documentation-worklow-rails/" target="_blank" rel="noopener noreferrer">Building a Documentation Workflow in Rails [Honeybadger]  &#x2197;</a></li>
			</ul>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
				subtitle
      }
    }
  }
`
